.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.poc-new-bike-form {
    width: 80%;
    max-width: 500px;
    text-align: start;
    padding: 10px;
}

.ul-items{
    padding: 5px;
    margin: 5px 0 20px 0;
    background-color: #ffffff;
    border-radius: 3px;
    height: auto;
    max-height: 200px;
    overflow-y:auto;
}

.ui-first {
    border-top: 1px solid #a5be00;
}

.ui-make,
.ui-model,
.ui-year,
.ui-size{
    padding: 5px;
    border-bottom: 1px solid #a5be00;
}

.list-item {
    list-style: none;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.list-item-label
{
   align-self: center;
    font: 15px/1.7 'Open Sans', sans-serif;
    color: #333;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
}

.form-radio
{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    position: sticky;
    background-color: #dae8dd;
    color: #666;
    top: 10px;
    height: 30px;
    width: 30px;
    max-width: 30px;
    border: 1px solid #779d8f ;
    border-radius: 50px;
    cursor: pointer;
    margin-right: 7px;
    outline: none;
}
.form-radio:checked::before
{
    position: absolute;
    font: 13px/1 'Open Sans', sans-serif;
    left: 11px;
    top: 7px;
    content: '\02143';
    transform: rotate(40deg);
}
.form-radio:hover
{
    background-color: #f7f7f7;
}
.form-radio:checked
{
    background-color: #f1f1f1;
}
