.add-note-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.note-container {
    padding: 1px;
    background-color: #ffffff;
}

.btn {
    display: flex;
    background-color: #9ba59d;
    box-shadow: none;
    border-radius: 3px;
    border: 0;
    color: #ffffff;
}

.div-spacer {
    flex: 1;
    align-self: center;
    justify-self: flex-start;
}

.div-font-awesome-chevron {
    display: flex;
    justify-content: flex-end;
}

.font-awesome-chevron {
    flex: 1;
    align-self: center;
    color: #dae8dd;
}

.btn-content {
    display: flex;
    flex: 1;
    align-self: center;
}

.note-detail {
    background-color: #ffffff;
}

.new-note-textarea,
.new-note-select {
    flex: 1;
    border: 1px solid #ddd;
    display: block;
    font-size: 1.1em;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-bottom: 10px;
    padding: 10px;
    text-align: left;
    width: 100%;
    border-radius: 4px;
}

.new-note-textarea {
    resize: vertical;
    width: 100%;
}

.note-content {
    text-align: start;
    padding: 0 10px 5px 10px;
    background-color: #ffffff;
    border-radius: 3px;
}

.new-note-form {
   width: 80%;
    max-width: 500px;
}

.note-type-items {
    padding: 5px;
    margin: 5px 0 20px 0;
    background-color: #ffffff;
    border-radius: 3px;
    height: auto;
    max-height: 200px;
    overflow-y: auto;
}

.delete-edit-button {
    margin: 2px 0 2px 0;
    background: transparent;
    border-style: solid;
    border-color: transparent;
    text-decoration: none;
    cursor: pointer;
}

.leftmargin {
    margin-left: 10px;
}

.grey {
    background-color: darkgray;
}

.collapse {
    display: none
}

.collapse.in {
    display: block
}

.ul-items {
    padding: 5px;
    margin: 5px 0 20px 0;
    background-color: #ffffff;
    border-radius: 3px;
    height: auto;
    max-height: 200px;
    overflow-y: auto;
}

.list-item-first {
    border-top: 1px solid #a5be00;
}

.list-item {
    list-style: none;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    border-bottom: 1px solid #a5be00;
}


.list-item-label {
    align-self: center;
    font: 15px/1.7 'Open Sans', sans-serif;
    color: #333;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
}

.note-radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    position: sticky;
    background-color: #dae8dd;
    color: #666;
    top: 10px;
    height: 30px;
    width: 30px;
    max-width: 30px;
    border: 1px solid #779d8f;
    border-radius: 50px;
    cursor: pointer;
    margin-right: 7px;
    outline: none;
}

.note-radio:checked::before {
    position: absolute;
    font: 13px/1 'Open Sans', sans-serif;
    left: 11px;
    top: 7px;
    content: '\02143';
    transform: rotate(40deg);
}

.note-radio:hover {
    background-color: #f7f7f7;
}

.note-radio:checked {
    background-color: #f1f1f1;
}
