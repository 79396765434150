.BikeCollection {
  background: #9ba59d;
  color: #dae8dd;
  border: 0;
  font-size: smaller;
  text-decoration: none;
  box-shadow: 0px 11px 21px -8px rgba(0, 0, 0, 0.5);
  margin: 40px 20px 0 20px;
  border-radius: 10px;
  max-width: 400px;
  max-height: 200px;
  cursor: pointer;
}

.BikeCollection__details_top {
  display: flex;
}

.image_container {
  flex: 1;
  background-color: white;
  border-radius: 10px 0 0 10px;
}

.add-bike-container{
  display: flex;
  flex-direction: column;
}

.new-bike-event-buttons {
  display: flex;
  justify-content: center;
}

.add-bike-container h2 {
  text-align: center;
}

.new-bike-form {
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: 30px;
  text-align: start;

}

.new-bike-select {
  flex: 1;
  border: 1px solid #ddd;
  display: block;
  font-size: 1.1em;
  margin-bottom: 10px;
  padding: 10px;
  text-align: left;
  width: 100%;
  border-radius: 4px;
}

.BikeCollection__image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 10px 10px 0 0;
}

.BikeCollection__details_right {
  flex: 1;
}

.gallery_item_container {
  padding: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  width: 275px;
}

.gallery_item_container {
  padding: 5px 0;
  text-align: center;
  opacity: 0.85;
}

.add-bike-event-button {
  background-color: #9ba59d;
  font-size: 1.1em;
  min-width: 75px;
  padding: 5px;
  margin: 5px;
  font-weight: bold;
  box-shadow: none;
  border-radius: 3px;
  border: 0;
  color: #ffffff;
  cursor: pointer;
}
