* {
  box-sizing: border-box;

}*:before,
 *:after {
   box-sizing: border-box;
 }

body {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-align: center;
    background-color: #464646;
  height: 100%;
  position: relative;
}

