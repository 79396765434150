.BikeCollectionPage {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px 0 10px 0;
}

.Button {
  background-color: #9ba59d;
  font-size: 1.1em;
  min-width: 75px;
  padding: 5px;
  margin: 5px;
  font-weight: bold;
  box-shadow: none;
  border-radius: 3px;
  border: 0;
  color: #ffffff;
  cursor: pointer;
}


