.main-container {
  min-height: 100vh; /* will cover the 100% of viewport */
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 60px; /* height of your footer */
}

.background-color {
  background-color: #dae8dd;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color:  #779d8f;
  color: #ffffff
}

footer a:link,
footer a:visited{
  color: #ffffff;
}

footer a:hover {
  color: #a5be00;
}