img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.BikeCollection__image {
    flex: 1;
    height: 100%;
    width: 100%;
    padding: 10px;
    object-fit: contain;
    border-radius: 10px 10px 0 0;
}


