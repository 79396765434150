.addnote-button, .addpos-button {
    margin: 0;
    color: #ffffff;
    background: transparent;
    border-style: solid;
    border-color: transparent;
    text-decoration: none;
    cursor: pointer;
}

.bike-detail-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 5px 0 5px;
}

.geometry-data {
    display: flex;
    margin-bottom: 1px;
    border-radius: 3px;
    text-align: start;
    padding: 5px;
    font-size: 14px;
}
.geo-left {
    flex: 1;
}

.geo-right {
    flex: 1;
}

.geo-alpha{
    font-weight: bold;
    color: #779d8f;
}

.geometry-image {
    border-radius: 3px;
    margin-top: 5px;
}

.button-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: #779d8f;
    border-radius: 3px;
}

.detail-section {
    display: flex;
    flex-direction: row;
}

.position-section, .notes-section {
    display: flex;
    flex-direction: column;
    padding: 5px 0 5px 0;
    width: 100%;
    max-width: 800px;
}

.left {
    flex: 1;
    text-align: start;
}

.right {
    flex: 1;
    text-align: start;
}